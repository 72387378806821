import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PageWelcome() {
    document.title = 'Mufleet - Welcome';

    return (
        <Suspense fallback={<p>loading ...</p>}>
            <div className="container">
                <Header />
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            <h1><b>Mufleet</b> is not available anymore</h1>
                        </p>
                        <p>Apologies ...</p>
                        <br />
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageWelcome;
